import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { Country } from "src/app/core/models/country";
import { UtilitiesService } from "src/app/core/service/utilities.service";
import Swal from "sweetalert2";
import * as _ from "lodash";
import { Categories } from "src/app/core/models/categorie";
import { PartenaireService } from "src/app/core/service/partenaire.service";
import { Partenaire } from "src/app/core/models/partenaire";
import { TypePartenaire } from "src/app/core/models/typePartenaire";
import { CountryService } from "src/app/core/service/country.service";
import { TypePartenaireService } from "src/app/core/service/type-partenaire.service";
import { UserService } from "src/app/core/service/user.service";
import { UserBineta } from "src/app/core/models/userBineta";
import { CategoriesService } from "src/app/core/service/categories.service";

@Component({
  selector: 'app-form-formateur',
  templateUrl: './form-formateur.component.html',
  styleUrls: ['./form-formateur.component.scss']
})
export class FormFormateurComponent implements OnInit {

  
  paramForm!: FormGroup;
  stepperIndex : number = 1;
  partenaireId : number;
  userId : number;
  itemUser : UserBineta = new UserBineta();
  @Input() itemToUpdate: Partenaire; // Pour signifier la mofification de l'element
  busySuscription!: Subscription;
  isUpate : boolean;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  itemsTypePartenaire : Country[] = [];
  itemsParentCategorie : Categories[] = [];
  listCategoriesId : any;
  itemsPays : Country[] = [];
  @Input() isBeginFormateur: boolean;
  user : any = {};

  constructor(
    private formBuilder: FormBuilder,
    private partenaireService : PartenaireService,
    private categoriesService : CategoriesService,
    private utilities: UtilitiesService,
    private userService: UserService,
    private countryService : CountryService,
    private typePartenaireService: TypePartenaireService
  ) {
    this.user = this.userService.getCurrentUserInfo();
  }

  createForm = () => {
    this.paramForm = this.formBuilder.group({
      id: [this.itemToUpdate?.id || ""],
      libelle : [this.itemToUpdate?.libelle || "", Validators.required],
      description: [this.itemToUpdate?.description || ""],
      email: [ (this.itemToUpdate?.email || this.user.email ) || "", Validators.required],
      paysId: [this.itemToUpdate?.paysId || "", Validators.required],
      typePartenaireId: [this.itemToUpdate?.typePartenaireId || "", Validators.required],
      situationGeographique: [this.itemToUpdate?.situationGeographique || ""],

      contactPersonneAContacter: [this.itemToUpdate?.contactPersonneAContacter || "", Validators.required],
      whatsappPersonneAContacter: [this.itemToUpdate?.whatsappPersonneAContacter || "", Validators.required],

      isCompany: [this.itemToUpdate?.isCompany || false],
      lienLogo: [this.itemToUpdate?.lienLogo || ""],
      pourcentageBineta: [this.itemToUpdate?.pourcentageBineta || ""],
    });
  };

  getFormFiledsValue = (field: string) => {
    return this.paramForm.get(field);
  };



  saveUser(item : any) {
    Swal.fire({
      title: "Formateur",
      text: "Vous êtes sur le point d'enregistrer un formateur. Voulez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0665aa",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then((result) => {
      if (result.value) {
        
        let itemAEnregistrer = {...item};

        itemAEnregistrer.indicatifWhatsapp = item?.indicatifTelephonique;
        itemAEnregistrer.contact1 = item?.indicatifTelephonique+""+item?.contact1;
        itemAEnregistrer.contactWhatsapp = item?.indicatifTelephonique+""+item?.contactWhatsapp;
        itemAEnregistrer.isPartenaire = true;
        itemAEnregistrer.password = item.contact1.replace(itemAEnregistrer.indicatifWhatsapp,"");

    var request = {
      datas: [
        itemAEnregistrer
      ]
    }

    console.log(" request ",request);
    

    this.busySuscription = (!itemAEnregistrer.id ? (this.userService.create(request)) : (this.userService.update(request)))
      .subscribe(
        res => {
          console.log("resul", res);

          if (!res['hasError']) {
            if (res['items'] && res['items'].length > 0) {

              this.utilities.showNotification("snackbar-success",
                this.utilities.formatMsgServeur(res['status']['message']),
                "bottom",
                "center");

                this.itemToUpdate.contactPersonneAContacter = itemAEnregistrer.contact1;
                this.itemToUpdate.whatsappPersonneAContacter = itemAEnregistrer.contactWhatsapp;
                this.itemToUpdate.email = itemAEnregistrer.email;
                this.itemToUpdate.libelle = itemAEnregistrer.nom +" "+itemAEnregistrer?.prenoms;
                
                this.createForm();
                this.userId = res['items'][0]?.id;
                this.stepperIndex = 2;
            }
          } else {
            if (res['status'] && res['status']['message']) {
              this.utilities.showNotification("snackbar-danger",
                this.utilities.formatMsgServeur(res['status']['message']),
                "bottom",
                "center");
            }
          }
        },
        err => {
          this.utilities.showNotification("snackbar-danger", this.utilities.getMessageEndPointNotAvailble(),
            "bottom",
            "center");
        }
      );

      }
    });
  }

  confirmSaveItem() {
    Swal.fire({
      title: "Formateur",
      text: (this.itemToUpdate?.id && this.itemToUpdate?.id > 0)
        ? "Vous êtes sur le point de modifier un formateur. Voulez-vous poursuivre cette action ?"
        : "Vous êtes sur le point d'enregistrer un formateur. Voulez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0665aa",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then((result) => {
      if (result.value) {
        this.saveItem(this.paramForm.value);
      }
    });
  }

  getParentCategories() {
    let req = {
      data: {
        isParent : false
      },
    };
    this.busySuscription = this.categoriesService.getByCriteria(req).subscribe(
      (res) => {
        if (res && res["items"]) {
          this.itemsParentCategorie = res["items"] as Categories[];
        } else {
          this.itemsParentCategorie = [];
        }
      },
      (err) => {}
    );
  }

  
  saveItem(item: Partenaire) {
    let itemAEnregistrer = {...item}

    itemAEnregistrer.idUserFormateur = this.userId;

    var request = {
      datas: [
        itemAEnregistrer
      ]
    } 

    this.busySuscription = (!itemAEnregistrer.id ? (this.partenaireService.create(request)) : (this.partenaireService.update(request)))
      .subscribe(
        res => {
          console.log("resul", res);

          if (!res['hasError']) {
            if (res['items'] && res['items'].length > 0) {

              this.utilities.showNotification("snackbar-success",
                this.utilities.formatMsgServeur(res['status']['message']),
                "bottom",
                "center");
                this.partenaireId = res['items'][0]?.id;
                this.stepperIndex = 3;
              //  this.closeModal.emit({saveSucces:true});
            }
          } else {
            if (res['status'] && res['status']['message']) {
              this.utilities.showNotification("snackbar-danger",
                this.utilities.formatMsgServeur(res['status']['message']),
                "bottom",
                "center");
            }
          }
        },
        err => {
          this.utilities.showNotification("snackbar-danger", this.utilities.getMessageEndPointNotAvailble(),
            "bottom",
            "center");
        }
      );
  }

  saveCategorieUser(item : any) {
    Swal.fire({
      title: "Categorie enseignés formateur",
      text: "Vous êtes sur le point d'enregistrer les catégories enseignées du formateur. Voulez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0665aa",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then((result) => {
      if (result.value) {
        let listToSave = [];
        this.listCategoriesId.forEach(element => {
          listToSave.push({
            categorieId : element,
            partenaireId : this.partenaireId
          })
        });

    var request = {
      datas: listToSave
    }

    this.busySuscription = this.partenaireService.createPartenaireCategorie(request)
      .subscribe(
        res => {
          console.log("resul", res);

          if (!res['hasError']) {
            if (res['items'] && res['items'].length > 0) {

              this.utilities.showNotification("snackbar-success",
                this.utilities.formatMsgServeur(res['status']['message']),
                "bottom",
                "center");
                this.closeModal.emit(true);
            }
          } else {
            if (res['status'] && res['status']['message']) {
              this.utilities.showNotification("snackbar-danger",
                this.utilities.formatMsgServeur(res['status']['message']),
                "bottom",
                "center");
            }
          }
        },
        err => {
          this.utilities.showNotification("snackbar-danger", this.utilities.getMessageEndPointNotAvailble(),
            "bottom",
            "center");
        }
      );

      }
    });
  }

  getPays() {
    let req = {
      data: {
      },
    };
    this.busySuscription = this.countryService.getByCriteria(req).subscribe(
      (res) => {
        if (res && res["items"]) {
          this.itemsPays = res["items"] as Country[];
          this.createForm();
        } else {
          this.itemsPays = [];
        }
      },
      (err) => {}
    );
  }

  getTypePartenaire() {
    let req = {
      data: {
      },
    };
    this.busySuscription = this.typePartenaireService.getByCriteria(req).subscribe(
      (res) => {
        if (res && res["items"]) {
          this.itemsTypePartenaire = res["items"] as TypePartenaire[];
          this.createForm();
        } else {
          this.itemsTypePartenaire = [];
        }
      },
      (err) => {}
    );
  }

  ngOnInit(): void {
    // Initialisation du forms group
    this.createForm();
    this.getPays();
    this.getTypePartenaire();
    this.getParentCategories();
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(" this.itemToUpdate ",this.itemToUpdate);
    
    if (changes["itemToUpdate"] && changes["itemToUpdate"].currentValue) {

      if(changes["itemToUpdate"].currentValue && changes["itemToUpdate"].currentValue.paysCode) {
        this.isUpate = true;
        this.createForm();
      }
     
    }else{
      this.isUpate = false;
    }
  }

}
