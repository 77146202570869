<div class="footer-container">

    <div class="left-part">
       
    </div>

    <div class="right-part">
        <p>&copy; Copyright BINETA {{currentYear}}</p>
        <!-- <p>Designed PIXEL GROUP</p> -->
    </div>
   
</div>
