import { Component, OnInit } from "@angular/core";

@Component({
  selector: 'app-details-affaire-facultative',
  templateUrl: './details-affaire-facultative.component.html',
  styleUrls: ['./details-affaire-facultative.component.scss']
})
export class DetailsAffaireFacultativeComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {
  }

}
