import { enumCodeACL } from "src/app/core/enumerator/enumerator";
import { RouteInfo } from "./sidebar.metadata";

export const ROUTES: RouteInfo[] = [
  // Tableau de bord
  // {
  //   path: "",
  //   title: "Statistique",
  //   moduleName: "dashboard",
  //   iconType: "home",
  //   icon: "tachometer",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   code: "MENU_STAT",
  //   acl: "",
  //   // codeAclAdmin: enumCodeACL.ADMIN,
  //   // codeAclCedente: enumCodeACL.CEDANTE,
  //   submenu: [
  //     {
  //       path: "/business/affaire-traitee",
  //       title: "Traitées",
  //       moduleName: "dashboard",
  //       iconType: "list",
  //       icon: "plus",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       // codeX: "TRESO-CAISSE-BUREAU-ENTREES",
  //       submenu: [],
  //     }
  //   ],
  // },

  {
    path: "/admin/dashboard/main",
    title: "Tableau de bord",
    moduleName: "dashboard",
    iconType: "home",
    icon: "tachometer",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    code: "DASHBORD-NAV",
    acl : '',
    submenu: [],
  },
  {
    path: "formations/formation",
    title: "Books & formations",
    moduleName: "formations",
    iconType: "edit",
    icon: "tachometer",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    code: "DASHBORD-NAV",
    acl : '',
    submenu: [],
  },

  {
    path: "formations/ebook",
    title: "EBooks",
    moduleName: "formations",
    iconType: "edit",
    icon: "tachometer",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    code: "DASHBORD-NAV",
    acl : '',
    submenu: [],
  },

  {
    path: "commandes/commande-list",
    title: "Commandes",
    moduleName: "commande",
    iconType: "edit",
    icon: "tachometer",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    code: "DASHBORD-NAV",
    acl : '',
    submenu: [],
  },
  

  // {
  //   path: "formations/mentor",
  //   title: "Espace Mentor",
  //   moduleName: "formations",
  //   iconType: "edit",
  //   icon: "tachometer",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   code: "DASHBORD-NAV",
  //   acl : '',
  //   submenu: [],
  // },
  {
    path: "freelancer",
    title: "Freelancer",
    moduleName: "freelancer",
    iconType: "edit",
    icon: "tachometer",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    code: "DASHBORD-NAV",
    acl : '',
    submenu: [],
  },
  // {
  //   path: "transactions",
  //   title: "Historique transactions",
  //   moduleName: "transactions",
  //   iconType: "list",
  //   icon: "tachometer",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   code: "DASHBORD-NAV",
  //   acl : '',
  //   submenu: [],
  // },
  // {
  //   path: "/admin/dashboard/main",
  //   title: "Espace Partenaire",
  //   moduleName: "dashboard",
  //   iconType: "user",
  //   icon: "tachometer",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   code: "DASHBORD-NAV",
  //   acl : '',
  //   submenu: [],
  // },

  // Administration acl ok
  {
    path: "",
    title: "Administration",
    moduleName: "administration",
    iconType: "lock",
    icon: "description",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    code: "MENU_ADMIN",
    submenu: [
      
      {
        path: "/administration/categories",
        title: "Catégorie formation",
        moduleName: "administration",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "GET-USER-LST",
        submenu: [],
      },

      {
        path: "/administration/domaine-freelancer",
        title: "Domaine freelancer",
        moduleName: "administration",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "GET-USER-LST",
        submenu: [],
      },

      {
        path: "/administration/categorie-ebook",
        title: "Catégorie ebook",
        moduleName: "administration",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "GET-USER-LST",
        submenu: [],
      },


      

      {
        path: "/administration/type-service",
        title: "Type de service",
        moduleName: "administration",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "GET-USER-LST",
        submenu: [],
      },
      {
        path: "/administration/services-partenaire",
        title: "Partenaire tiers",
        moduleName: "administration",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "GET-USER-LST",
        submenu: [],
      },
      {
        path: "/administration/formateurs",
        title: "Structure de formation",
        moduleName: "administration",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "GET-USER-LST",
        submenu: [],
      },
      {
        path: "/administration/publicite",
        title: "Publicité",
        moduleName: "administration",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "GET-USER-LST",
        submenu: [],
      },
      // {
      //   path: "/administration/services-partenaire",
      //   title: "Partenaire tiers",
      //   moduleName: "administration",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   code: "GET-USER-LST",
      //   submenu: [],
      // },
      {
        path: "/administration/country",
        title: "Pays",
        moduleName: "administration", 
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "GET-USER-LST",
        submenu: [],
      },
      {
        path: "/administration/devise",
        title: "Dévise",
        moduleName: "administration", 
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "GET-USER-LST",
        submenu: [],
      },
      {
        path: "/administration/utilisateurs",
        title: "Utilisateurs",
        moduleName: "administration",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "GET-USER-LST",
        submenu: [],
      },
      {
        path: "/administration/roles",
        title: "Roles",
        moduleName: "administration",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "GET-ROL-LST",
        submenu: [],
      },
      // {
      //   path: "/administration/fonctionnalites",
      //   title: "Fonctionnalites",
      //   moduleName: "administration",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   // code: "73166694",
      //   submenu: [],
      // },
      // {
      //   path: "/administration/logs",
      //   title: "Logs",
      //   moduleName: "administration",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   code: "GET-USER-LST",
      //   submenu: [],
      // },
      {
        path: "/administration/historique-connexion",
        title: "Historique connexion",
        moduleName: "administration",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "GET-USER-LST",
        submenu: [],
      },
       {
        path: "/administration/logs-system",
        title: "Logs système",
        moduleName: "administration",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        code: "GET-USER-LST",
        submenu: [],
      }
      
    ],
  },
];
