import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

 transform(input: any, count?: number) {
        if(!input) return;

        if(count && input.length > count){
            return input.substring(0, count)+'...';
        }
        else if(input.length > 230){
            return input.substring(0, 230)+'...';
        }
        else {
            return input;
        }
    }
}
