import { Injectable } from '@angular/core';
import { RestClientService } from './rest-client.service';

@Injectable({
  providedIn: 'root'
})
export class PartenaireService {

  constructor(private restClient:RestClientService) { }

  create = (body:any) => {
    return this.restClient.post('partenaire/create',body)
  } 
 
  getByCriteria = (body:any) => {
    return this.restClient.post('partenaire/getByCriteria',body);
  }

  getPartenaireByUser= (body:any) => {
    return this.restClient.post('partenaireUser/getByCriteria',body);
  }

  update = (body:any) => {
    return this.restClient.post('partenaire/update',body)
  }

  createPartenaireCategorie = (body:any) => {
    return this.restClient.post('partenaireCategorie/create',body)
  }

  validateOrCancel = (body:any) => {
    return this.restClient.post('partenaire/validateOrCancel',body)
  }

  delete = (body:any) => {
    return this.restClient.post('partenaire/delete',body)
  }
  
}
