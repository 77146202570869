export class UserBineta {
  id: number;
  nom?: string;
  email?: string;
  prenoms?: string;
  contact1: string;
  contact2: string;
  contactWhatsapp?:string;
  indicatifWhatsapp?:string;
  indicatifTelephonique?: string;
  contactFormat?:any;
  password?:string;
  codeSms?:string;
  isConfirmAccount?:boolean;
  dateEnvoiCode?:string;
  paysId?:number;
  isDeleted?:boolean;
  createdAt?:string;
  updatedAt? : string;
  createdBy? : number;
  isMentor?:boolean;
  updatedBy?:number;
  canalEnvoiCodeValidation?:string;
  isPartenaire?:boolean;
  parentId?:number;
  derniereConnexion?:string;
  login?:string;
  profil?:string;
  isAbonne?:boolean;
  isFreelancer?:boolean;
  paysLibelle?:string
}
