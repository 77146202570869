<nav #navbar class="navbar active">
  <div class="container-fluid">
    <div class="navbar-header">
      <a href="#" onClick="return false;" class="navbar-toggle collapsed"
        (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
      <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event, 'overlay-open')"></a>
      <a class="navbar-brand">
        <img src="assets/images/images-app/logo_bineta.png" class="logo-app" alt="" />
      </a>
    </div>
    <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <ul class="float-start collapse-menu-icon">
        <li>
          <button mat-icon-button (click)="callSidemenuCollapse()" class="sidemenu-collapse">
            <i class="material-icons-outlined icon-color"> menu </i>
          </button>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <!-- Full Screen Button -->
        <li class="fullscreen">
          <button mat-icon-button (click)="callFullscreen()" class="nav-notification-icons">
            <i class="material-icons-outlined icon-color"> fullscreen </i>
          </button>
        </li>
        <!-- #END# Full Screen Button -->
        <!-- #START# Notifications-->
        <li class="nav-item li-notification" ngbDropdown>
          <button mat-icon-button ngbDropdownToggle class="nav-notification-icons"
            (click)="openToogle((isOpen = !isOpen))">
            <i class="material-icons-outlined icon-color">
              notifications_active
            </i>
            <sup *ngIf="listeNotifications && listeNotifications.length > 0">{{
              listeNotifications?.length | moneyFormat
              }}</sup>
          </button>
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 700px; max-height: 450px" [perfectScrollbar]>
              <ul class="menu">
                <li class="nfc-header">
                  <h5 class="mb-0">Notifications</h5>
                </li>

                <li *ngIf="!listeNotifications || listeNotifications?.length == 0" style="text-align: center">
                  Aucune notification disponible
                </li>

                <li>
                  <a href="#" *ngFor="let notification of listeNotifications" onClick="return false;"
                    style="border-top: 1px solid #eceeee">
                    <!-- <span class="table-img msg-user">
                      <i class="fa fa-plus" [ngClass]="{'nfc-green' : notification.typeNotificationId == 1}"></i>
                    </span> -->
                    <span class="menu-info">
                      <span class="menu-title">{{ notification?.message }}
                      </span>
                      <!-- <span class="menu-desc mt-2">
                        <i class="material-icons">access_time</i>
                        {{ getDuree(notification.createdAt) }}
                      </span> -->
                    </span>
                    <span class="nfc-close">
                      <b *ngIf="notification?.hasValidation" class="badge badge-success pull-right" (click)="
                          openModal(notification, templateTraiterDossier)
                        ">
                        Traiter
                      </b>
                      <b *ngIf="
                          !notification?.hasValidation &&
                          (notification?.code?.toLowerCase() ==
                            codeCirucit?.RADIO?.toLowerCase() ||
                            notification?.code?.toLowerCase() ==
                              codeCirucit?.LABO?.toLowerCase())
                        " class="badge badge-danger pull-right" (click)="
                          openModal(notification, templateVisualiserResulat)
                        ">Interprètation
                      </b>
                      <b *ngIf="
                          !notification?.hasValidation &&
                          notification?.code?.toLowerCase() ==
                            codeCirucit?.RES_RADIO?.toLowerCase()
                        " class="badge badge-warning pull-right" (click)="
                          openModal(notification, templateVisualiserResulat)
                        ">
                        Résultat
                      </b>
                      <!-- <app-feather-icons [icon]="'x'" [class]="'user-menu-icons'"></app-feather-icons> -->
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <!-- <a class="nfc-read-more"> Toutes les notifications</a> -->
          </div>
        </li>
        <!-- #END# Notifications-->
     
        <li class="nav-item user_profile" ngbDropdown>
          <div class="chip dropdown-toggle" ngbDropdownToggle class="nav-notification-icons pt-0">
            <!-- <img src="{{ userImg || 'assets/images/images-app/logo.png' }}" class="user_img" width="32" height="32"
              alt="User" /> -->
            <span>{{
              currentUser?.nom + " " + currentUser?.prenoms | uppercase
              }}</span>
           
          </div>
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 300px" [perfectScrollbar]>
              <ul class="menu">
                <li class="body">
                  <ul class="user_dw_menu">
                    <li>
                      <a href="#" onClick="return false;" (click)="updatePassword()">
                        <app-feather-icons [icon]="'user'" [class]="'user-menu-icons'"></app-feather-icons>Modifier mot
                        de passe
                      </a>
                    </li>

                    <!-- <li>
                      <a href="#" onClick="return false;" (click)="openModal({},templateSelectFonction)">
                        <app-feather-icons [icon]="'check'" [class]="'user-menu-icons'"></app-feather-icons>Changer de fonction
                      </a>
                    </li> -->

                    <!-- <li>
                      <a href="#" onClick="return false;">
                        <app-feather-icons [icon]="'mail'" [class]="'user-menu-icons'"></app-feather-icons>Inbox
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick="return false;">
                        <app-feather-icons [icon]="'settings'" [class]="'user-menu-icons'"></app-feather-icons>Settings
                      </a>
                    </li> -->
                    <li>
                      <a href="javascript:void(0);" (click)="logout()">
                        <app-feather-icons [icon]="'log-out'" [class]="'user-menu-icons'"></app-feather-icons>
                        Deconnexion
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <!-- #END# Tasks -->
      </ul>
    </div>
  </div>
</nav>

<!-- Traiter demande -->
<ng-template #templateSelectFonction>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Changement de fonction</h4>
    <button mat-icon-button class="pull-right" (click)="modalRef.hide()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="modal-body">
    <form class="m-1">
      <div class="row">
        <app-select-fonction (closeModal)="closeModalFormSelectForm($event)"></app-select-fonction>
      </div>
    </form>
  </div>
</ng-template>
