<ng-container *ngIf="stepperIndex == 1">
  <div class="row">
    <div class="col-12 mb-1">
      <label> Nom<sup>*</sup></label>
      <input class="form-control custom-input" placeholder="Nom" autocomplete="off" [(ngModel)]="itemUser.nom"
        name="nom" type="text" />
    </div>

    <div class="col-12 mb-1">
      <label> Prénoms <sup>*</sup></label>
      <input class="form-control custom-input" placeholder="Prénoms" autocomplete="off" [(ngModel)]="itemUser.prenoms"
        name="prenoms" type="text" />
 
    </div>

    <div class="col-md-4 mb-1">
      <label> Indicatif télephone <sup>*</sup></label>
      <input class="form-control custom-input" placeholder="Indicatif" autocomplete="off"
        [(ngModel)]="itemUser.indicatifTelephonique" name="indicatifTelephonique" type="text" />

    </div>

    <div class="col-md-8 mb-1">
      <label> Numéro télephone <sup>*</sup></label>
      <input class="form-control custom-input" placeholder="N° Tel" autocomplete="off" [(ngModel)]="itemUser.contact1"
        name="contact1" type="text" />

    </div>


    <div class="col-md-12 mb-1">
      <label> N° Whatsapp <sup>*</sup></label>
      <input class="form-control custom-input" placeholder="n°Whatsapp" autocomplete="off"
        [(ngModel)]="itemUser.contactWhatsapp" name="contactWhatsapp" type="text" />

    </div>

    <div class="col-md-12 mb-1">
      <label> Email <sup>*</sup></label>
      <input class="form-control custom-input" placeholder="Email" autocomplete="off" [(ngModel)]="itemUser.email"
        name="email" type="text" />

    </div>

    <div class="col-12 mb-1">
      <button type="submit" (click)="saveUser(itemUser)" class="btn btn-md custom_btn_primary pull-right">
        Enregistrer et continuer
      </button>
    </div>

  </div>
</ng-container>

<ng-container *ngIf="stepperIndex == 2">
  <form class="m-1" [formGroup]="paramForm" (ngSubmit)="confirmSaveItem()">
    <div [ngBusy]="{
        busy: busySuscription,
        message: 'Veuillez patienter ...',
        backdrop: false
      }">

      <div class="row">
        <div class="col-12 mb-3">
          <label>Type de structure :
          </label>

          <ng-select formControlName="typePartenaireId" name="typePartenaireId" id="typePartenaireId">
            <ng-option *ngFor="let typePartenaire of itemsTypePartenaire" [value]="typePartenaire?.id">{{
              typePartenaire.libelle }}</ng-option>
          </ng-select>
        </div>
      </div>

      <div class="row">
        <div class="col-12 mb-3">
          <label>Pays :
          </label>

          <ng-select formControlName="paysId" name="paysId" id="paysId">
            <ng-option *ngFor="let pays of itemsPays" [value]="pays?.id">{{ pays.libelle }}</ng-option>
          </ng-select>
        </div>
      </div>


      <div class="row">
        <div class="col-12 mb-3">
          <label>Libellé :
            <span class="required_champs">*</span>
          </label>
          <input class="form-control custom-input" placeholder="Libellé" autocomplete="off" formControlName="libelle"
            name="libelle"
            [class.errorInputForm]="getFormFiledsValue('libelle')?.invalid && getFormFiledsValue('libelle')?.touched"
            type="text" />
          <small class="text-danger"
            *ngIf="(getFormFiledsValue('libelle')?.dirty || getFormFiledsValue('libelle')?.touched) && getFormFiledsValue('libelle')?.invalid && getFormFiledsValue('libelle')?.errors?.['required']">
            Ce champ est obligatoire
          </small>
        </div>
      </div>

      <div class="row">
        <div class="col-12 mb-3">
          <label>Email :
            <span class="required_champs">*</span>
          </label>
          <input class="form-control custom-input" placeholder="Email formateur" autocomplete="off"
            formControlName="email" name="email"
            [class.errorInputForm]="getFormFiledsValue('email')?.invalid && getFormFiledsValue('email')?.touched"
            type="text" />
          <small class="text-danger"
            *ngIf="(getFormFiledsValue('email')?.dirty || getFormFiledsValue('email')?.touched) && getFormFiledsValue('email')?.invalid && getFormFiledsValue('email')?.errors?.['required']">
            Ce champ est obligatoire
          </small>
        </div>
      </div>

      <div class="row">
        <div class="col-12 mb-3">
          <label>Lien logo :
            <!-- <span class="required_champs">*</span> -->
          </label>
          <input class="form-control custom-input" placeholder="Lien logo" autocomplete="off"
            formControlName="lienLogo" name="lienLogo"
            type="text" />
         
        </div>
      </div>

      <!-- <div class="row">
        <div class="col-12 mb-3">
          <label>N° Whatsapp :
            <span class="required_champs">*</span>
          </label>
          <input class="form-control custom-input" placeholder="Ex : +225000000000" autocomplete="off"
            formControlName="whatsappPersonneAContacter" name="whatsappPersonneAContacter"
            [class.errorInputForm]="getFormFiledsValue('whatsappPersonneAContacter')?.invalid && getFormFiledsValue('whatsappPersonneAContacter')?.touched"
            type="text" />
          <small class="text-danger"
            *ngIf="(getFormFiledsValue('whatsappPersonneAContacter')?.dirty || getFormFiledsValue('whatsappPersonneAContacter')?.touched) && getFormFiledsValue('whatsappPersonneAContacter')?.invalid && getFormFiledsValue('whatsappPersonneAContacter')?.errors?.['required']">
            Ce champ est obligatoire
          </small>
        </div>
      </div>

      <div class="row">
        <div class="col-12 mb-3">
          <label>N° télephone :
            <span class="required_champs">*</span>
          </label>
          <input class="form-control custom-input" placeholder="Ex : +225000000000" autocomplete="off"
            formControlName="contactPersonneAContacter" name="contactPersonneAContacter"
            [class.errorInputForm]="getFormFiledsValue('contactPersonneAContacter')?.invalid && getFormFiledsValue('contactPersonneAContacter')?.touched"
            type="text" />
          <small class="text-danger"
            *ngIf="(getFormFiledsValue('contactPersonneAContacter')?.dirty || getFormFiledsValue('contactPersonneAContacter')?.touched) && getFormFiledsValue('contactPersonneAContacter')?.invalid && getFormFiledsValue('contactPersonneAContacter')?.errors?.['required']">
            Ce champ est obligatoire
          </small>
        </div>
      </div> -->

      <div class="row" *ngIf="!isBeginFormateur">
        <div class="col-12 mb-3">
          <label>Pourcentage BINETA:
            <span class="required_champs">*</span>
          </label>
          <input class="form-control custom-input" placeholder="Pourcentage BINETA" autocomplete="off"
            formControlName="pourcentageBineta" name="pourcentageBineta"
            [class.errorInputForm]="getFormFiledsValue('pourcentageBineta')?.invalid && getFormFiledsValue('pourcentageBineta')?.touched"
            type="text" />
          <small class="text-danger"
            *ngIf="(getFormFiledsValue('pourcentageBineta')?.dirty || getFormFiledsValue('pourcentageBineta')?.touched) && getFormFiledsValue('pourcentageBineta')?.invalid && getFormFiledsValue('pourcentageBineta')?.errors?.['required']">
            Ce champ est obligatoire
          </small>
        </div>
      </div>

      <div class="custom-btn-save-manager">
        <button type="submit" class="btn btn-md custom_btn_primary pull-right" [disabled]="!paramForm.valid">
          {{ !itemToUpdate.id ? 'Enregistrer' : 'Modifier'}}
        </button>

        <button type="button" class="btn btn-md btn-cancel pull-right" (click)="closeModal.emit(false)">
          Annuler
        </button>
      </div>
    </div>
  </form>
</ng-container>

<ng-container *ngIf="stepperIndex == 3">
  
  <div class="row">
    <div class="col-12 mb-3">
      <label
        >Catégorie  :
      </label>
 
      <ng-select
      [(ngModel)]="listCategoriesId"
      [multiple]="true"
      name="parentId"
      id="parentId"
    >
      <ng-option
        *ngFor="let categorie of itemsParentCategorie"
        [value]="categorie?.id"
        >{{ categorie.libelle }}</ng-option
      >
    </ng-select>
    </div>

    <div class="col-12 mb-1">
      <button type="button" (click)="saveCategorieUser()" class="btn btn-md custom_btn_primary pull-right">
        Enregistrer
      </button>
    </div>

  </div>

</ng-container>