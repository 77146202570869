<ng-container>
    <div class="element-per-page">
        <label> Elements par page </label>
        <select name="element-par-page" [(ngModel)]="itemPagination" (change)="changePagination()">
          
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="">Tous</option>
        </select>
    </div>
</ng-container>