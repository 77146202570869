import { Injectable } from '@angular/core';
import { RestClientService } from './rest-client.service';

@Injectable({
  providedIn: 'root'
})
export class TypePartenaireService {

  constructor(private restClient:RestClientService) { }
  
  create = (body:any) => {
    return this.restClient.post('typePartenaire/create',body)
  } 
 
  getByCriteria = (body:any) => {
    return this.restClient.post('typePartenaire/getByCriteria',body);
  }

  update = (body:any) => {
    return this.restClient.post('typePartenaire/update',body)
  }

  delete = (body:any) => {
    return this.restClient.post('typePartenaire/delete',body)
  }
  
}
